import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WalletProvider } from "@solana/wallet-adapter-react";
import { ConnectionProvider } from "@solana/wallet-adapter-react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import loadContract from "./utils/loadContract";
import {
  HomeScreen,
  TokenScreen,
  FaqScreen,
  ContactScreen,
  LoadingScreen,
  ErrorScreen,
  TokenDistScreen,
  FaucetScreen,
} from "./screens";
import Header from "./components/Header";
import GlobalContext from "./context/GlobalContext";
import connectWallet from "./utils/connectWallet";
import Footer from "./components/Footer";
import Wallets from "./components/Wallets";
import { useMemo } from "react";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
  },
});

function App() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState({
    address: null,
    balance: null,
    chainID: null,
  });

  const [contract, setContract] = useState(null);
  const [icoState, setIcoState] = useState({
    tokensAvailable: null,
    investorBalance: null,
  });

  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new MathWalletAdapter(),
    ],
    []
  );

  const endpoint = useMemo(() => clusterApiUrl("mainnet-beta"), []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const handleConnectWallet = async () => {
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="app-wrapper relative">
      <ConnectionProvider endpoint={endpoint}>
      <ChakraProvider theme={theme}>
        <WalletProvider wallets={wallets} autoConnect>
      <GlobalContext.Provider
        value={{
          provider,
          setProvider,
          signer,
          setSigner,
          account,
          setAccount,
          handleConnectWallet,
          contract,
          setContract,
          icoState,
          setIcoState,
          loading,
          setLoading,
        }}
      >
        {!loading ? (
          <div className="relative z-10">
            <Header />
            <ToastContainer
              position="top-center"
              theme="dark"
              toastStyle={{
                backgroundColor: "#1e40af",
                fontWeight: "bold",
                fontFamily: "poppins",
                borderRadius: "5rem",
              }}
            />
            <div className="screen-wrapper">
              <Routes>
                <Route
                  path="/"
                  element={
                    <div className="">
                      <ToastContainer
                        position="top-center"
                        theme="dark"
                        toastStyle={{
                          backgroundColor: "#1e40af",
                          fontWeight: "bold",
                          fontFamily: "poppins",
                          borderRadius: "5rem",
                        }}
                      />
                      <div className="screen-wrapper">
                      <div className="bg-indigo-600">
                    
                      <img src="/geo3.png" alt="back" className="w-full hidden xl:flex absolute top-0 inset-0 z-0 h-200 xl:h-160" />
                      </div>
                        <section id="HomeScreen">
                         <div className="z-10">
                          <HomeScreen />
                          </div>
                        </section>
                        {isModalOpen && (
                                <div className="modal"> {/* Modal */}
                                  <button onClick={closeModal}>
                                    <img src="/quit.png" alt="Close" style={{ width: '25px', height: '25px', filter: 'invert(1)', right: 10, top: 10, position: 'absolute'}}/>
                                  </button>
                                  <Wallets /> {/* Affichage du composant Wallets dans la modal */}
                                </div>
                              )}

                        <section id="TokenScreen">
                          <TokenScreen />
                        </section>
                        <section id="TokenDistScreen">
                          <TokenDistScreen />
                        </section>
                        <section id="FaqScreen">
                          <FaqScreen />
                        </section>
                      </div>
                    </div>
                  }
                />
                <Route path="/wallets" element={<Wallets />} /> {/* Add this route */}
              </Routes>
            </div>
            <div className="flex justify-center items-end">
              <Footer />
            </div>
          </div>
        ) : (
          <LoadingScreen />
        )}
      </GlobalContext.Provider>
      </WalletProvider>
      </ChakraProvider>
    </ConnectionProvider>
    </div>
  );
}

export default App;
