import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="hidden sm:inline">
      <button className="navbar-title"><a href="#Top">Home</a></button>
      <button className="navbar-title"><a href="#TokenScreen">Token</a></button>
      <button className="navbar-title"><a href="#TokenDistScreen">Strategy</a></button>
      <button className="navbar-title"><a href="#FaqScreen">FAQ</a></button>
    </div>
  );
};

export default Navbar;
