import React from "react";
import { Grid } from "react-loader-spinner";

const LoadingScreen = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[95vh]">
      {/*<Grid color="#a855f7" height={120} width={120} />*/}
      <div className="text-[#a855f7] absolute top-1/3 aldrich-regular font-bold text-3xl">
        Palladium
      </div>
      <img src="/logoloading.png" alt="Logo" className="image w-44 h-44 mx-2 z-10 my-10" />
    </div>
  );
};

export default LoadingScreen;
