import { VStack, Button, Image, Text } from "@chakra-ui/react";
import { useWallet } from "@solana/wallet-adapter-react";
import React from "react";

const Wallets = () => {
  const { select, wallets, publicKey, disconnect } = useWallet();

  return !publicKey ? (
    React.createElement(VStack, { gap: 4 },
      wallets.filter((wallet) => wallet.readyState === "Installed").length > 0 ? (
        wallets.filter((wallet) => wallet.readyState === "Installed").map((wallet) =>
          React.createElement(Button, {
            key: wallet.adapter.name,
            onClick: () => select(wallet.adapter.name),
            w: "64",
            size: "lg",
            fontSize: "md",
            leftIcon: React.createElement(Image, {
              src: wallet.adapter.icon,
              alt: wallet.adapter.name,
              h: 6,
              w: 6,
            }),
          }, wallet.adapter.name)
        )
      ) : (
        React.createElement(Text, null, "No wallet found. Please download a supported Solana wallet")
      )
    )
  ) : (
    React.createElement(VStack, { gap: 4 },
      React.createElement(Text, null, publicKey.toBase58()),
      React.createElement(Button, { onClick: disconnect }, "disconnect wallet")
    )
  );
};

export default Wallets;
