import React from "react";
import millify from "millify";
import TokenDetails from "../components/TokenDetails";

const TokenScreen = () => {

  return (
    <div className="bg-[#000d26] mt-4 xl:mt-56">
    <div className="grid grid-cols-1 gap-x-10 gap-y-10 sm:grid-cols-3 xl:grid-cols-6 xl:gap-x-6 overflow-x-hidden z-10">
    
        {/* Token Details */}
        <div className="col-span-3 flex justify-center items-center z-10">
        
        <div className="card8 mt-20 xl:mt-40">
            <div className="text-white text-3xl font-bold text-center aldrich-regular p-4">Token Details</div>

          <TokenDetails title="Name" value="Palladium" />
          <TokenDetails title="Symbol" value="PLD" />
          <TokenDetails title="Decimals" value="9" />

          <TokenDetails title="Total Supply" value={millify(1000000000)} />
          <TokenDetails title="Maximum Supply" value={millify(1000000000)} />
          <TokenDetails
            title="Token Address"
            value="4VA2gtQ48gKexxLBabjeXQQKm9YScERD847RTz8R8pui"
          />
        </div>
        </div>
      <div className="col-span-3 flex justify-center items-center z-10 ">
      <div className="mx-16">
        <div className="bg-[#000d26] h-144 rounded-3xl">

        <div className="text-center text-white text-3xl aldrich-regular mx-32 mt-16 mb-10">RULES</div>
        <div className="font-bold text-lg text-center text-white aldrich-regular mb-10 mx-4">Number of PLD received = SOL you send / total SOL balance from the refund address * 500,000,000</div>
        <div className="font-bold text-lg text-center text-white aldrich-regular mb-10 mx-4"> As the token sale wraps up, brace yourself for an exciting move – we're gearing up to launch the token on Solana's top-tier decentralized exchanges, fueled by liquidity pools. Buckle up, because the entry price will be set beyond the sale price!</div>
        <div className="flex flex-row space-between justify-center gap-5 mb-10">
          <img src="/ray.png" alt="raydium" className="h-14 flex flex-col" />
          <img src="/jupiter.png" alt="jupiter" className="h-14 flex flex-col" />
          <img src="/orca.png" alt="orca" className="h-14 flex flex-col" />
        </div>
      </div>
      </div>
      </div>
      
      {/* Balance */}

      {/*<div className="flex justify-center items-center w-full flex-col">
        <div
          className={icoState.investorBalance ? "card text-center" : "hidden"}
        >
          <div className="card-container">
            <div className="font-bold border-b-2 border-blue-500 mb-3">
              Your Balance
            </div>
            <div>
              SOL :{" "}
              {account.balance ? millify(account.balance, { precision: 6 }) : 0}{" "}
              SOL
            </div>
            <div>PLD : {icoState.investorBalance} PLD</div>
          </div>
        </div>

        }

        <div className="card4">
          <div className="flex justify-center items-center flex-col">
            <div className="m-3">Price: 0.000016 SOL</div>
            <input
              className="input"
              type="number"
              min={0.2}
              max={10}
              placeholder="Enter a number of SOL"
              value={userAmount}
              onChange={(e) => {
                setUserAmount(e.target.value);
              }}
            />
            {userAmount >= 0.2 && userAmount <= 10 ? (
              <div className="mb-3 text-white">
                Total received:{" "}
                {millify(62500 * userAmount, {
                  precision: 4,
                })}{" "}
                PLD
              </div>
            ) : null}
            {userAmount < 0.2 && userAmount !== "" ? (
              <div className="text-red-500 mb-3">Min Tokens: 0.2 </div>
            ) : null}
            {userAmount > 10 ? (
              <div className="text-red-500 mb-3">Max Tokens: 10 </div>
            ) : null}

          </div>
        </div>
      </div>*/}
      </div>
    </div>
  );
};

export default TokenScreen;
