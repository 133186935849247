import React from "react";
import FaqItem from "../components/FaqItem";
import faq from "../data/faq";

const FaqScreen = () => {
  return (
    <div>
    <div className="h-32"></div>
    <div className="flex flex-col justify-center items-center text-center">
        <div className="mb-10">
          <div className="text-2xl font-bold aldrich-regular mx-4">FAQ</div>
        </div>
      {faq.map((item, index) => {
        return (
          <div key={index}>
            <FaqItem question={item.question} answer={item.answer} />
          </div>
        );
      })}
    
    </div>
    </div>
  );
};

export default FaqScreen;
