import { useState } from "react";
import { CgMenuRound, CgCloseO } from "react-icons/cg";
import { Link } from "react-router-dom";

const NavbarMobile = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen((prevState) => !prevState);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <div className="sm:hidden flex flex-1 justify-start items-center">
      <nav>
        <div onClick={toggleNav}>
          {isNavOpen ? (
            <CgCloseO size={32} color="cyan" />
          ) : (
            <CgMenuRound size={32} color="cyan" />
          )}
        </div>

        {/* Links */}
        <div
          className={`${
            isNavOpen ? `flex` : `hidden`
          } flex-col p-6 bg-indigo-900 absolute top-20 left-0 mx-4 my-4 min-w-[200px] rounded-xl sidebar`}
        >
         <button className="navbar-title text-xl" onClick={closeNav}><a href="#Top">Home</a></button>
          <button className="navbar-title text-xl" onClick={closeNav}><a href="#TokenScreen">Token</a></button>
          <button className="navbar-title text-xl" onClick={closeNav}><a href="#TokenDistScreen">Strategy</a></button>
          <button className="navbar-title text-xl" onClick={closeNav}><a href="#FaqScreen">FAQ</a></button>
        </div>
      </nav>
    </div>
  );
};

export default NavbarMobile;
