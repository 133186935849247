import React from "react";

const TokenDetails = ({ title, value }) => {
  if (title === "Token Address") {
    return (
      <div>
        <div className="flex justify-center items-center flex-col card4">
          <div className="text-center aldrich-regular" title={value}>
            {title} : {value.substring(0, 8)}......{value.substring(34, 42)}
          </div>
          <div className="m-2 aldrich-regular">
            <a
              className="btn text-sm mt-2 aldrich-regular"
              href="https://explorer.solana.com/address/4VA2gtQ48gKexxLBabjeXQQKm9YScERD847RTz8R8pui"
              target="_blank"
              rel="noreferrer noopener"
            >
              View on explorer
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="card4 aldrich-regular flex flex-row justify-between">
        <div className="flex flex-col text-slate-500">
        {title} :
        </div>
        <div className="flex justify-end mr-4 font-bold flex-col">{value}</div>
      </div>
    </div>
  );
};

export default TokenDetails;
