import React from "react";

const Footer = () => {
  return (
    <div className="flex justify-center aldrich-regular font-bold m-3">
      Made with &#10084;
    </div>
  );
};

export default Footer;
