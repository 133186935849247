const tokenDistribution = [
  {
    name: "Token Sale",
    tokens: 500000000,
    percentage: "5à%",
  },
  {
    name: "Liquidity pool",
    tokens: 500000000,
    percentage: "5À%",
  },
];

export default tokenDistribution;
