import React from "react";

import Circle from "../components/Circle";
import SaleEnds from "../components/SaleEnds";

const HomeScreen = () => {
  const scrollToTokenScreen = () => {
    const tokenScreenElement = document.getElementById("TokenScreen");
    if (tokenScreenElement) {
      tokenScreenElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="mt-6 xl:mt-20">
      <div className="grid grid-cols-1 gap-x-10 sm:grid-cols-4 xl:grid-cols-6 xl:gap-x-5 overflow-x-hidden z-10">
      <div className="col-span-4 flex xl:hidden mt-6 xl:mt-0 justify-center items-center z-10 "> {/* Moitié droite */}
        <div className="bg-[#000d26] rounded-3xl mb-10">
            <div className="justify-center flex mb-4">
              <img src="/logofinal1.png" alt="Logo" className="w-24 h-24 mx-2 z-10" />
            </div>
            <div className="text-2xl aldrich-regular text-indigo-400 text-center">Palladium token</div>
            <div className="text-xl aldrich-regular text-indigo-200 text-center mb-10">Build on Solana</div>
            <div className="text-xl aldrich-regular text-white z-10 mx-6 text-center mb-10">
            They didn't even need to dig into the earth to find it!
            </div>
          </div>
        </div>
        {/*<div className="col-span-2 flex justify-center items-center z-10">
          <SaleEnds />
        </div>*/}
        <div className="col-span-4 flex justify-center items-center z-10">
        {/* ICO Details */}

        <div className="card6 mt-4">
          <div className="mt-12">
          </div>
          <div className="mb-10">
            <Circle />
          </div>
        </div>
      </div>
        <div className="col-span-2 hidden xl:flex mt-6 xl:mt-0 justify-center items-center z-10 "> {/* Moitié droite */}
        <div>
            <div className="justify-center flex mb-4">
              <img src="/logofinal1.png" alt="Logo" className="w-24 h-24 mx-2 z-10" />
            </div>
            <div className="text-3xl aldrich-regular text-indigo-400 text-center">Palladium token</div>
            <div className="text-2xl aldrich-regular text-indigo-200 text-center mb-10">Build on Solana</div>
            <div className="text-xl aldrich-regular text-white z-10 mx-14 text-center mb-4">
            They didn't even need to dig into the earth to find it!
            </div>
          </div>
        </div>
      </div>
      <div className="arrow z-10 hidden sm:block mt-8 2xl:mt-10" onClick={scrollToTokenScreen}>
                <span className="z-10"></span>
                <span className="z-10"></span>
                <span className="z-10"></span>
            </div>
    </div>
  );
};

export default HomeScreen;
