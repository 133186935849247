const faq = [
  {
    question: "What is the purpose of this token sale?",
    answer:
      "SOL raised in this sale will be added to the existing 500 million PLD in our liquidity pools, enabling token exchange",
  },
  {
    question: "What will be the selling price on DEXs?",
    answer:
      "It will be set at a higher price than the token was sold for during the token sale. Get ready!",
  },
  {
    question: "What will be the number of PLD received in your wallet following the sale?",
    answer:
      "Number of PLD received = SOL you send / total SOL balance from the refund address * 500,000,000",
  },
];

export default faq;
