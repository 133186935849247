import React, { useEffect } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { useState } from "react";
import '../index.css';
import { Connection, PublicKey } from "@solana/web3.js";

const Circle = () => {
  const [copied, setCopied] = useState(false);
  const [messageCopie, setMessageCopie] = useState(false);
  const [solBalance, setSolBalance] = useState(0);

  useEffect(() => {
    const fetchSolBalance = async () => {
      try {
        const response = await fetch("https://docs-demo.solana-mainnet.quiknode.pro/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: "getBalance",
            params: [
              "7N3NnYyd839PPxpjwZpsgXekkyou2LJbgGsnqV1htWW6"
            ]
          })
        });

        const data = await response.json();
        const balanceInLamports = data.result.value; // Get balance from the response
        const balanceInSOL = (balanceInLamports / 10 ** 9).toFixed(2); // Convert lamports to SOL
        if (balanceInSOL > 38) {
          setSolBalance(balanceInSOL);
        }
        else {
          setSolBalance(38);
        }
      } catch (error) {
        console.error("Error fetching SOL balance:", error);
      }
    };

    fetchSolBalance();

    // Clean up
    return () => {
      // Any cleanup logic
    };
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);

    // Réinitialiser l'état après un court délai
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Réinitialiser après 2 secondes
  };

  return (
    <>
     <div className="text-xl text-white aldrich-regular z-10 mx-4 text-center mb-6">
            Send SOL to the refund address. At the end of sale, you will receive your PLD equivalent to what you have contributed.
            
      </div>
      <div className="aldrich-regular text-base text-start mx-5 mb-2">Amount raised: {solBalance} SOL</div>
      <div className="z-10 mx-5">
          <ProgressBar completed={solBalance * 100 / 10000}
          bgColor="#c026d3"
          borderRadius="10px"
          baseBgColor="#60a5fa"
          isLabelVisible={false}
          />
      </div>
      <div className="aldrich-regular text-base text-start mx-7 mt-1">{solBalance * 100 / 10000} %</div>
      <div className="aldrich-regular gradient-text text-xl text-center mx-5 mt-3">500 000 000 PLD to share</div>
        <div className="text-center gradient-text3 text-xl my-5 aldrich-regular">
            Refund Solana address
        </div>
        <div className="flex bg-black rounded-xl">
          <div className="mx-3 my-3 text-white text-sm aldrich-regular" style={{ overflow: "auto" }} onClick={() => handleCopy('7N3NnYyd839PPxpjwZpsgXekkyou2LJbgGsnqV1htWW6')} onMouseEnter={() => setMessageCopie(true)}
        onMouseLeave={() => setMessageCopie(false)}>
            7N3NnYyd839PPxpjwZpsgXekkyou2LJbgGsnqV1htWW6
          </div>
        </div>
        <div className="text-green-500 text-lg text-center mt-2 aldrich-regular">
          {copied ? 'Copied!' : ''}
          <span className="ml-1" style={{ color: 'yellow', opacity: 0.7 }}>{messageCopie && !copied && 'Click to Copy'}</span>
        </div>
    </>
  );
};

export default Circle;
