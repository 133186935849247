import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import GlobalContext from "../context/GlobalContext";
import Navbar from "./Navbar";
import NavbarMobile from "./NavbarMobile";

const Header = () => {
  const { provider, account, handleConnectWallet } = useContext(GlobalContext);

  const navigate = useNavigate();

  return (
    <div className="header">
      
      <div className="flex gap-2">
        <NavbarMobile />

        <div
          className="aldrich-regular text-[21px] mt-1 hover:text-blue-400"
          onClick={() => navigate("/")}
        >
          Palladium
        </div>
      </div>
      <div className="flex justify-center items-center text-center">
        <Navbar />
      </div>
      <div className="flex">
      <a href="https://t.me/+F9Ya00joWURmODU0" target="_blank" rel="noopener noreferrer">
          <img src="/telegram.png" alt="Twitter" className="mx-4" style={{width: 40, height: 40}}/>
        </a>
        <a href="https://twitter.com/PalladiumSol/" target="_blank" rel="noopener noreferrer">
          <img src="/twitter.png" alt="Twitter" className="" style={{width: 39, height: 39}} />
        </a>
      </div>
    </div>
  );
};

export default Header;
